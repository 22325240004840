// source: https://github.com/ianstormtaylor/slate/blob/main/site/examples/ts/paste-html.tsx
import { jsx } from 'slate-hyperscript';
import { Transforms } from 'slate';
const ELEMENT_TAGS = {
  A: el => ({
    type: 'link',
    url: el.getAttribute('href')
  }),
  BLOCKQUOTE: () => ({
    type: 'quote'
  }),
  H1: () => ({
    type: 'heading-one'
  }),
  H2: () => ({
    type: 'heading-two'
  }),
  H3: () => ({
    type: 'heading-three'
  }),
  H4: () => ({
    type: 'heading-four'
  }),
  H5: () => ({
    type: 'heading-five'
  }),
  H6: () => ({
    type: 'heading-six'
  }),
  IMG: el => ({
    type: 'image',
    url: el.getAttribute('src')
  }),
  LI: () => ({
    type: 'list-item'
  }),
  OL: () => ({
    type: 'numbered-list'
  }),
  P: () => ({
    type: 'paragraph'
  }),
  PRE: () => ({
    type: 'code'
  }),
  UL: () => ({
    type: 'bulleted-list'
  })
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({
    code: true
  }),
  DEL: () => ({
    strikethrough: true
  }),
  EM: () => ({
    italic: true
  }),
  I: () => ({
    italic: true
  }),
  S: () => ({
    strikethrough: true
  }),
  STRONG: () => ({
    bold: true
  }),
  U: () => ({
    underline: true
  })
};
const INLINE_STYLES = {
  'font-style': value => value === 'italic' ? {
    italic: true
  } : {},
  'font-weight': value => value === 'bold' || parseInt(value, 10) >= 600 ? {
    bold: true
  } : {}
};
function deserialize(el) {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }
  const {
    nodeName
  } = el;
  let parent = el;
  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();
  if (children.length === 0) {
    children = [{
      text: ''
    }];
  }
  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }
  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map(child => jsx('text', attrs, child));
  }

  // Convert inline CSS on span elements generated by Google Docs
  if (nodeName === 'SPAN') {
    const attrs = {};
    for (let i = 0; i < el.style.length; i++) {
      const propertyName = el.style[i];
      if (INLINE_STYLES[propertyName]) {
        const propertyValue = el.style.getPropertyValue(propertyName);
        const propertyStyle = INLINE_STYLES[propertyName](propertyValue);
        Object.assign(attrs, propertyStyle);
      }
    }
    return children.map(child => jsx('text', attrs, child));
  }
  return children;
}
function withHtml(editor) {
  const {
    insertData,
    isInline,
    isVoid
  } = editor;
  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };
  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  };
  editor.insertData = data => {
    const html = data.getData('text/html');
    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }
    insertData(data);
  };
  return editor;
}
export default withHtml;